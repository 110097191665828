import { Box, CircularProgress } from '@material-ui/core';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { MovieCard } from '@/components/ThemedMovieCard';
import { getFilteredSearchMovies } from '@/graphql/hooks/movies';
import { useSearchUrl } from '@/hooks/useSearchUrl';
import { movieClick } from '@/utils/analytics';
import getMeta from '@/utils/getMeta';

import { MovieCardWrapper, SearchContainer, SearchWrapper } from './Search.styles';

import { Typography } from '../Typography';

interface SearchProps {
  fetchSearchResults: (query: string, after?: string | null) => void;
  results?: ReturnType<typeof getFilteredSearchMovies>;
  isFetching: boolean;
  endCursor?: string | null;
  hasNextPage?: boolean;
}
export const Search: FC<SearchProps> = ({ results, fetchSearchResults, isFetching, endCursor, hasNextPage }) => {
  const { urlQuery, updateUrl } = useSearchUrl();
  const [areCollectionsLoading, setAreCollectionsLoading] = useState(false);
  const observerTarget = useRef(null);

  const handleSubmit = useCallback(
    async (after?: string | null) => {
      updateUrl(urlQuery, after);
      setAreCollectionsLoading(true);
      await fetchSearchResults(urlQuery, after);
      setAreCollectionsLoading(false);
    },
    [fetchSearchResults, updateUrl, urlQuery]
  );

  useEffect(() => {
    const currentTarget = observerTarget.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          handleSubmit(endCursor);
        }
      },
      { threshold: 1 }
    );

    if (currentTarget) {
      observer.observe(currentTarget);
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget);
      }
    };
  }, [endCursor, handleSubmit]);

  return (
    <Box mt="8rem" minHeight="150vh">
      <SearchContainer>
        <Box ml="1rem">
          <Typography variant="titleLarge" customColor="#EBEBEB" fontWeight="500">
            Sökresultat för &rdquo;{urlQuery}&rdquo;
          </Typography>
        </Box>
        <SearchWrapper>
          {results?.map((movie) => (
            <MovieCardWrapper key={movie.magineId}>
              <MovieCard
                customId={movie.customId}
                title={movie.title}
                thumbnail={movie.image}
                onClick={() => movieClick(movie, getMeta('pageType'), 'search')}
              />
            </MovieCardWrapper>
          ))}
        </SearchWrapper>
        {areCollectionsLoading && (
          <Box textAlign="center">
            <CircularProgress color="primary" />
          </Box>
        )}

        {results && results.length <= 0 && !isFetching ? (
          <div>"Inga träffar"</div>
        ) : (
          hasNextPage && <div ref={observerTarget} />
        )}
      </SearchContainer>
    </Box>
  );
};
