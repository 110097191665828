import axios from 'axios';
import Cookies from 'js-cookie';
import { FC, PropsWithChildren, createContext, useContext, useEffect, useReducer } from 'react';

import { IAuthUser } from '@/models/IAuthUser.interface';
import { IUser } from '@/models/IUser.interface';

const initialState = {
  auth: {
    error: false,
    newUser: null,
    openId: null,
    registered: null,
    sessionId: null,
    token: null,
    userId: null,
  },
  user: null,
  isLoading: true,
};

export enum accountActionTypes {
  INIT = 'INIT',
  ERROR = 'ERROR',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  USERDATA = 'USERDATA',
  LOADING = 'LOADING',
}

export interface State {
  auth: IAuthUser;
  user: IUser | null;
  isLoading: boolean;
}

interface Dispatch {
  dispatch: (action: Action) => void;
  getUserData: (user: IAuthUser) => void;
}

type Action =
  | { type: accountActionTypes.INIT; payload: IAuthUser }
  | { type: accountActionTypes.ERROR; payload: boolean }
  | { type: accountActionTypes.LOGIN; payload: IAuthUser }
  | { type: accountActionTypes.LOGOUT }
  | { type: accountActionTypes.USERDATA; payload: IUser }
  | { type: accountActionTypes.LOADING; payload: boolean };

const AccountStateContext = createContext<State | undefined>(undefined);
const AccountDispatchContext = createContext<Dispatch | undefined>(undefined);

function accountReducer(state: State, action: Action): State {
  switch (action.type) {
    case accountActionTypes.INIT:
      return {
        ...state,
        auth: action.payload,
      };

    case accountActionTypes.ERROR:
      return {
        ...state,
        auth: {
          ...state.auth,
          error: action.payload,
        },
      };

    case accountActionTypes.USERDATA:
      return {
        ...state,
        user: action.payload,
      };

    case accountActionTypes.LOGIN:
      return {
        ...state,
        auth: action.payload,
      };

    case accountActionTypes.LOGOUT:
      document.cookie = 'auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC';
      // if(process.env.NODE_ENV === 'production'){
      //   Cookies.remove('auth', {path: '/', domain: 'drakenfilm.se'});
      // } else {
      //   Cookies.remove('auth');
      // }
      return initialState;
    case accountActionTypes.LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

type AccountProviderProps = PropsWithChildren<{
  authenticated: IAuthUser | null;
}>;

const AccountProvider: FC<AccountProviderProps> = ({ children, authenticated }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  const getUserData = async (user: IAuthUser) => {
    dispatch({
      type: accountActionTypes.LOADING,
      payload: true,
    });
    axios
      .post<IUser>(`/api/auth/users/${user.userId}`, {
        token: user.token,
      })
      .then((userdata) => {
        dispatch({
          type: accountActionTypes.USERDATA,
          payload: userdata.data,
        });
      })
      .finally(() => {
        dispatch({
          type: accountActionTypes.LOADING,
          payload: false,
        });
      });
  };

  useEffect(() => {
    if (authenticated) {
      dispatch({
        type: accountActionTypes.INIT,
        payload: authenticated,
      });
      getUserData(authenticated);
    } else {
      dispatch({ type: accountActionTypes.LOGOUT });
      dispatch({
        type: accountActionTypes.LOADING,
        payload: false,
      });
    }
  }, [authenticated]);

  return (
    <AccountStateContext.Provider value={state}>
      <AccountDispatchContext.Provider value={{ dispatch, getUserData }}>{children}</AccountDispatchContext.Provider>
    </AccountStateContext.Provider>
  );
};

function useAccountState() {
  const context = useContext(AccountStateContext);
  if (context === undefined) {
    throw new Error('useAccountState must be used within a AccountProvider');
  }
  return context;
}

function useAccountDispatch() {
  const context = useContext(AccountDispatchContext);
  if (context === undefined) {
    throw new Error('useAccountDispatch must be used within a AccountProvider');
  }
  return context;
}

export { AccountProvider, useAccountState, useAccountDispatch };
